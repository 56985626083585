// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

// eslint-disable-next-line no-unused-vars
var defaultToolbar = ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak media table forecolor backcolor fullscreen'];

// const toolbar = ['searchreplace visualblocks bold italic underline strikethrough alignleft aligncenter alignright outdent indent blockquote undo redo removeformat subscript superscript', 'hr bullist numlist link image charmap preview media table forecolor backcolor fullscreen']

// const toolbar = ['newdocument undo redo | formatselect visualaid|cut copy paste selectall| bold italic underline strikethrough |codeformat blockformats| superscript subscript  | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent |  removeformat ',
//   'code  bullist numlist | lists image media table link |fullscreen help toc fullpage restoredraft nonbreaking insertdatetime visualchars visualblocks searchreplace spellchecker pagebreak anchor charmap  pastetext print preview hr']
var toolbar = [
'newdocument undo redo | searchreplace cut copy paste pastetext selectall | fullpage visualchars print preview fullscreen',
'fontsizeselect bold italic underline strikethrough codeformat blockformats superscript subscript forecolor backcolor |formatselect alignleft aligncenter alignright alignjustify lineheight outdent indent bullist numlist lists| removeformat',
'image media table link anchor charmap hr nonbreaking pagebreak insertdatetime '];

export default toolbar;