import "core-js/modules/es.array.for-each";import "core-js/modules/es.date.to-string";import "core-js/modules/es.number.constructor";import "core-js/modules/es.number.to-fixed";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.replace";import "core-js/modules/web.dom-collections.for-each";import _slicedToArray from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/slicedToArray"; //
//
//
//
//
//
//
//
//

/**
   * docs:
   * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
   */
import editorImage from './components/EditorImage.vue';
import plugins from './plugins';
import toolbar from './toolbar';
import load from './dynamicLoadScript';

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = "https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js";
// const tinymceCDN = "plugins/tinymce4.3.3/tinymce-v4.3.3.min.js";
// const tinymceCDN = "plugins/tinymce5/tinymce/tinymce.min.js";
var tinymceCDN = 'plugins/tinymce/tinymce.min.js';

export default {
  name: 'Tinymce',
  components: { editorImage: editorImage },
  props: {
    id: {
      type: String, "default": function _default()
      {
        return (
          'vue-tinymce-' +
          Number(new Date()) +
          String((Math.random() * 1000).toFixed(0)));

      } },

    value: {
      type: String,
      "default": '' },

    toolbar: {
      type: Array,
      required: false, "default": function _default()
      {
        return [];
      } },

    menubar: {
      type: String,
      "default": 'file edit insert view format table' },

    height: {
      type: [Number, String],
      required: false,
      "default": 360 },

    width: {
      type: [Number, String],
      required: false,
      "default": 'auto' } },


  data: function data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        // en: "en",
        // es: "es_MX",
        // ja: "ja"
        zh: 'zh_CN' } };


  },
  computed: {
    containerWidth: function containerWidth() {var
      width = this.width;

      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return "".concat(width, "px");
      }
      return width;
    } },

  watch: {
    value: function value(val) {var _this2 = this;
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(function () {return window.tinymce.get(_this2.tinymceId).setContent(val || '');});
      }
    } },

  mounted: function mounted() {
    this.init();
  },
  created: function created() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  destroyed: function destroyed() {
    this.destroyTinymce();
  },
  methods: {
    init: function init() {var _this3 = this;
      // dynamic load tinymce from cdn
      load(tinymceCDN, function (err) {
        if (err) {
          _this3.$message.error(err.message);
          return;
        }
        _this3.initTinymce();
      });
    },
    initTinymce: function initTinymce() {var _this4 = this;
      // eslint-disable-next-line no-underscore-dangle
      var _this = this;

      window.tinymce.init({
        selector: "#".concat(this.tinymceId),
        language: this.languageTypeList.zh,
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar, // false禁用工具栏（隐藏工具栏）
        plugins: plugins,

        // disabled:true, //禁用
        // menubar: this.menubar,
        menubar: false, // 隐藏、显示最上方menu菜单
        browser_spellcheck: false, // 拼写检查
        branding: false, // 隐藏、显示水印
        statusbar: true, // 隐藏、显示编辑器底部的状态栏

        powerpaste_word_import: 'propmt', // 参数可以是propmt, merge, clear，效果自行切换对比
        powerpaste_html_import: 'propmt', // propmt, merge, clear
        powerpaste_allow_local_images: true,
        paste_data_images: true, // 允许粘贴图像

        end_container_on_empty_block: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['aliyuncs.com'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: function init_instance_callback(editor) {
          _this.editor = editor;
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on('Change KeyUp SetContent', function () {
            _this4.hasChange = true;
            _this.$emit('input', editor.getContent());
          });
        },
        setup: function setup(editor) {
          editor.on('FullscreenStateChanged', function (e) {
            _this.fullscreen = e.state;
          });
        },
        // file_picker_callback(callback, value, meta) {
        //   const input = document.createElement('input')
        //   input.setAttribute('type', 'file')
        //   input.onchange = function () {
        //     const file = this.files[0]
        //     const form = new FormData()
        //   }

        //   input.click()
        // },
        // 整合七牛上传
        // images_dataimg_filter(img) {
        //   setTimeout(() => {
        //     const $image = $(img)
        //     $image.removeAttr('width')
        //     $image.removeAttr('height')
        //     if ($image[0].height && $image[0].width) {
        //       $image.attr('data-wscntype', 'image')
        //       $image.attr('data-wscnh', $image[0].height)
        //       $image.attr('data-wscnw', $image[0].width)
        //       $image.addClass('wscnph')
        //     }
        //   }, 0)
        //   return img
        // },
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          if (blobInfo.blob().size > 1024 * 2048) {
            _this.handleError('上传图片尺寸不能超过2M！');
            _this.clearBlobImages();
            success([]);
          } else {
            var file = new window.File(
            [blobInfo.blob()],
            blobInfo.filename(),
            {
              type: blobInfo.blob().type });



            Api.Upload({ file: file }).then(function (_ref) {var _ref2 = _slicedToArray(_ref, 2),err = _ref2[0],res = _ref2[1];
              if (err) {
                failure('出现未知问题，刷新页面，或者联系程序员');
                console.log(err);
              } else {
                console.log(res);
                success(res);
              }
              _this.clearBlobImages();
            });
          }
        }
        // images_upload_url:
        //   process.env.VUE_APP_API_PREFIX_URL
        //   + process.env.VUE_APP_UPLOAD_URL
        //   + '/imgUpload'
      });
    },
    clearBlobImages: function clearBlobImages() {
      var ct = this.editor.getContent();

      ct = ct.replace(/<img\ssrc\=\"data:image.+>/gi, '');
      this.editor.setContent(ct);
      // this.$emit('input', ct)
    },
    destroyTinymce: function destroyTinymce() {
      var tinymce = window.tinymce.get(this.tinymceId);

      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen');
      }
      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent: function setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent: function getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    imageSuccessCBK: function imageSuccessCBK(arr) {
      var _this = this;
      // arr.forEach(v => {
      //   window.tinymce.get(_this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`)
      // })

      arr.forEach(function (v) {
        window.tinymce.
        get(_this.tinymceId).
        insertContent("<img class=\"wscnph\" style=\"max-width:100%\" src=\"".concat(
        v, "\" >"));

      });
    } } };